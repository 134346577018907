import logo from './logo.svg';
import './App.css';
import { DeepChat } from "deep-chat-react";
import {React} from "react";
import { useState } from "react"
import { CgClose } from "react-icons/cg";

function App() {
  const [showChatBox, setShowChatBox] = useState(false);
  const [selectedLang, setSelectedLang] = useState('English');
  const [placeholder, setPlaceholder] = useState('Type Here !');
  const [placeholderMap, setPlaceholderMap] = useState({
    'English': 'Type Here!',
    'తెలుగు': 'ఇక్కడ టైపు చేయండి!'
  });
  const [textinputConfig,setTextInputConfig] = useState({
    placeholder: {
      text: 'Type Here !'
    }
  })

  const [requestConfig, setRequestConfig] = useState({
    'url': 'https://getexperts.online/api/chat',
    'additionalBodyProps': {
      'language': selectedLang
    }
  })

  const chatToggler = () => {
    if(showChatBox)
      setShowChatBox(false)
    else
    {
      setShowChatBox(true)
    
    }
    window.parent.postMessage({
      'func': 'parentFunc',
      'message': 'Message text from iframe.'
  }, "*");
  };

  const handleLanguageChange = (e) => {
    console.log("language changed");
    setSelectedLang(e.target.value);

    textinputConfig['placeholder']['text'] = placeholderMap[e.target.value];
    setTextInputConfig(textinputConfig)
    requestConfig["additionalBodyProps"]["language"] = e.target.value;
    setRequestConfig(requestConfig)
    console.log("text input config ", textinputConfig);
  }
  const chatboxstyle = {
    "position": "relative"
  };
  const chatHeader = {
    "position": "relative",
    "display":"flex",
    "box-shadow": "0 4px 8px rgba(0, 0, 0, 0.1)",
    "border": "1px solid #d3d3d3",
    "border-bottom": 0,
    "align-items": "center",
    "background-color": "rgb(241, 255, 230)"
  };

  const chatBody = {
    "position": "relative"
  };
  const closeIconStyles = {
    "position": "absolute",
    "z-index": "1000",
    /* right: 5px; */
    "background": "none",
    "border": "none",
    "outline": "none",
    "padding": "10px 5px",
    "right": "0px",
    "cursor": "pointer"
  }
  const chatIconStyle = {
    "cursor": "pointer"
  }
  const textHeader = {
    "padding": "10px 5px",
   
    "font-weight": "bold",
   
    "flex-grow": 3
  }
  const chatheaderLogo = {
    // "display": "inline",
    "flex-grow":1
  }
  const chatHeaderLogoImage = {
    "width": "42px",
    "padding": "5px 10px"
  }
  // const textinputConfig = {
  //   placeholder: {
  //     text: 'Type Here !'
  //   }
  // }
  return (

   
    <div className="App" >
      {showChatBox? (<div style={chatboxstyle}>

        <div style={chatHeader}>
          <div style={chatheaderLogo}> <img style={chatHeaderLogoImage} src='/ap_new2.png'></img> </div>
          <div style={textHeader}>APSCSCL Chatbot</div>
          <div><select value={selectedLang} onChange={handleLanguageChange}><option>English</option><option>తెలుగు</option></select></div>
          <button style={closeIconStyles} onClick={chatToggler}> <CgClose /></button>

    </div>

        <div style={chatBody}>

        {selectedLang == 'English' ?(<deep-chat  request='{
        "url": "https://getexperts.online/api/chat",
        "additionalBodyProps": {
          "language": "en"
        }
      }'  
      attachmentContainerStyle='{
        "backgroundColor": "#f0f8ff",
        "border": "1px solid #d3d3d3",
        "boxShadow": "0 2px 5px rgba(0, 0, 0, 0.1)"
    }' 
    inputAreaStyle='{
        "backgroundColor": "#ffffff",
        "border": "1px solid #d3d3d3",
        "boxShadow": "0 2px 5px rgba(0, 0, 0, 0.1)"
    }' 
    chatStyle='{
        "backgroundColor": "#ffffff",
        "borderRadius": "0px",
        "boxShadow": "0 4px 8px rgba(0, 0, 0, 0.1)",
        "fontFamily": "\"Helvetica Neue\", Helvetica, Arial, sans-serif",
        "color": "#333"
    }'
    textInput={JSON.stringify(textinputConfig)}
      ></deep-chat>): ''}
      
      {selectedLang == 'తెలుగు' ?(<deep-chat  request='{
        "url": "https://getexperts.online/api/chat",
        "additionalBodyProps": {
          "language": "te"
        }
      }'  
      attachmentContainerStyle='{
        "backgroundColor": "#f0f8ff",
        "border": "1px solid #d3d3d3",
        "boxShadow": "0 2px 5px rgba(0, 0, 0, 0.1)"
    }' 
    inputAreaStyle='{
        "backgroundColor": "#ffffff",
        "border": "1px solid #d3d3d3",
        "boxShadow": "0 2px 5px rgba(0, 0, 0, 0.1)"
    }' 
    chatStyle='{
        "backgroundColor": "#ffffff",
        "borderRadius": "0px",
        "boxShadow": "0 4px 8px rgba(0, 0, 0, 0.1)",
        "fontFamily": "\"Helvetica Neue\", Helvetica, Arial, sans-serif",
        "color": "#333"
    }'
    textInput={JSON.stringify(textinputConfig)}
      ></deep-chat>): ''}
      </div>
      
      </div>):""}

      {/* <DeepChat request={"url": "http://localhost:3001","method": "POST","headers": {"customName": "customHeaderValue"},"additionalBodyProps": {"customBodyField": "customBodyValue"}}/> */}
     { !showChatBox ? (<img style={chatIconStyle} width="200px" src="/01-v2.png" onClick={chatToggler}></img>):"" }
    </div>
  );
}

export default App;
